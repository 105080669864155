.site-layout-header {
}

.site-layout-header .logo {
    width: 120px;
    height: 31px;
    margin: 16px 28px 16px 0;
    float: left;
}


/* .site-layout-background {
    background: #F5F7FA;
} */

.site-layout-background ::-webkit-scrollbar-track{border-radius: 0;}
.site-layout-background ::-webkit-scrollbar{-webkit-appearance: none;width: 3px;height: 10px;}
.site-layout-background ::-webkit-scrollbar-thumb{cursor: pointer;border-radius: 3px;background: rgba(0,0,0,.25);transition: color .2s ease;}

.public-layout-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    text-align: center;
    vertical-align: middle;
}

.site-layout-logo{
    height: 32px;
    margin: 16px;
    
}
.extra-hidden{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block
}