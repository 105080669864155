.header {
  flex-wrap: nowrap;
  .null {
    flex-grow: 1;
  }
  .navList {
    font-weight: normal;
  }
  .navItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    cursor: pointer;
    // color: #fff;
    line-height: normal;
    font-size: 20px;
  }
  .link {
    // margin-top: 4px;
    // color: #fff;
    font-size: 13px;
  }
}
