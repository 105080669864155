.w-e-text-container {
  z-index: 1 !important;
}

.w-e-menu {
  z-index: 2 !important;
}

.w-e-toolbar{
  z-index: 2 !important;
}
